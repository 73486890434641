<template>
 <div>
  
   <div class="breadCurmb mt-3">
      <label>
        <router-link to="/">{{ $t("Home") }}</router-link>
      </label>
      <span>/</span>
      <label active>{{ $t("SystemConstant") }}</label>
      <span>/</span>
      <label>
        <router-link :to="{name: 'package'}">{{ $t("Packages") }}</router-link>
      </label>
      <span>/</span>
      <label active>{{ $route.params.id? $t("Edit"): $t('Add') }}</label>

    </div>

  <v-toolbar-title class="mt-3">{{$route.params.id? $t('EditPackage')  : $t('AddNewPackage') }}</v-toolbar-title>


   <v-tabs class="mt-2"
      v-model="activeName"
      background-color="primary"
      dark
    >
      <v-tab>{{ $t('PrimaryData') }}</v-tab>
      <v-tab>{{ $t('PackageDetails') }}</v-tab>
      <v-tab>{{ $t('PackageFeatures') }}</v-tab>
   </v-tabs>

    <v-tabs-items v-model="activeName">
       <v-tab-item>
           <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col class="py-0" :cols="6">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.name_ar`),
              }"
            >
              <label>{{$t('NameAr')}}</label>
              <v-text-field
                class="d-block my-2"
                type="text"
                name="name_ar"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('NameAr')"
                hide-details
                dense
                outlined
                v-model="formData.name.ar"
              ></v-text-field>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.name_ar`)"
            >
              {{ errors.first(`addEditValidation.name_ar`) }}
            </div>
          </v-col>


             <v-col class="py-0" :cols="6">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.name_en`),
              }"
            >
            <label>{{$t('NameEn')}}</label>
              <v-text-field
                class="d-block my-2"
                type="text"
                name="name_en"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('NameEn')"
                hide-details
                dense
                outlined
                v-model="formData.name.en"
              ></v-text-field>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.name_en`)"
            >
              {{ errors.first(`addEditValidation.name_en`) }}
            </div>
          </v-col>


          <v-col class="py-0" :cols="6">
            <div
                :class="{
                'has-error': errors.has(`addEditValidation.app_name_name_ar`),
              }"
            >
              <label>{{$t('NameAr')}} ({{$t('app')}})</label>
              <v-text-field
                  class="d-block my-2"
                  type="text"
                  name="app_name_name_ar"
                  data-vv-scope="addEditValidation"
                  v-validate="'required'"
                  :data-vv-as="$t('NameAr')"
                  hide-details
                  dense
                  outlined
                  v-model="formData.app_name.ar"
              ></v-text-field>
            </div>

            <div
                class="help-block"
                v-if="errors.has(`addEditValidation.app_name_name_ar`)"
            >
              {{ errors.first(`addEditValidation.app_name_name_ar`) }}
            </div>
          </v-col>

             <v-col class="py-0" :cols="6">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.app_name_name_en`),
              }"
            >
            <label>{{$t('NameEn')}} ({{$t('app')}})</label>
              <v-text-field
                class="d-block my-2"
                type="text"
                name="app_name_name_en"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('NameEn')"
                hide-details
                dense
                outlined
                v-model="formData.app_name.en"
              ></v-text-field>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.app_name_name_en`)"
            >
              {{ errors.first(`addEditValidation.app_name_name_en`) }}
            </div>
          </v-col>

             <v-col class="py-0" :cols="6">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.price_before_discount`),
              }"
            >
            <label>{{$t('PriceBeforeDiscount')}}</label>
              <v-text-field
                class="d-block my-2"
                type="number"
                name="price_before_discount"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('PriceBeforeDiscount')"
                hide-details
                dense
                outlined
                v-model="formData.price_before_discount"
              ></v-text-field>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.price_before_discount`)"
            >
              {{ errors.first(`addEditValidation.price_before_discount`) }}
            </div>
          </v-col>

            <v-col class="py-0" :cols="6">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.price`),
              }"
            >
            <label>{{$t('Price')}}</label>
              <v-text-field
                class="d-block my-2"
                type="number"
                name="price"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('Price')"
                hide-details
                dense
                outlined
                v-model="formData.price"
              ></v-text-field>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.price`)"
            >
              {{ errors.first(`addEditValidation.price`) }}
            </div>
          </v-col>

          <v-col class="py-0" :cols="6">
            <div :class="{'has-error': errors.has('addEditValidation.file')}">
              <!-- File Input -->
              <v-file-input
                  class=" my-6"
                  name="file"
                  data-vv-scope="addEditValidation"
                  v-validate="'required'"
                  :data-vv-as="$t('File')"
                  :error-messages="fileErrors"
                  @change="handleFileUpload($event)"
                  outlined
                  dense
                  hide-details="auto"
                  accept="image/*"
                  placeholder="Choose an image file"
                  v-model="formData.file"
              ></v-file-input>
            </div>
            <div class="help-block" v-if="errors.has('addEditValidation.file')">
              {{ errors.first('addEditValidation.file') }}
            </div>
            <!-- Image Preview -->
            <div v-if="imageUrl" class="image-preview">
              <img :src="imageUrl" alt="Image preview" />
            </div>
          </v-col>

           <v-col class="py-0" :cols="6">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.cost`),
              }"
            >
            <label>{{$t('Cost')}}</label>
              <v-text-field
                class="d-block my-2"
                type="number"
                name="cost"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('Cost')"
                hide-details
                dense
                outlined
                v-model="formData.cost"
              ></v-text-field>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.cost`)"
            >
              {{ errors.first(`addEditValidation.cost`) }}
            </div>
          </v-col>

        </v-row>
      </v-container>
    </v-card-text>

       </v-tab-item>
        <v-tab-item>
        
           <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col class="py-0" :cols="6">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.number_of_meals`),
              }"
            >
             <label>{{$t('NumberOfMeals')}}</label>
              <v-text-field
                class="d-block my-2"
                type="text"
                name="number_of_meals"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('NumberOfMeals')"
                hide-details
                dense
                outlined
                v-model="formData.number_of_meals"
              ></v-text-field>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.number_of_meals`)"
            >
              {{ errors.first(`addEditValidation.number_of_meals`) }}
            </div>
          </v-col>

             <v-col class="py-0" :cols="6">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.number_of_days`),
              }"
            >
            <label>{{$t('NumberOfDays')}}</label>
              <v-text-field
                class="d-block my-2"
                type="text"
                name="number_of_days"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('NumberOfDays')"
                hide-details
                dense
                outlined
                v-model="formData.number_of_days"
              ></v-text-field>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.number_of_days`)"
            >
              {{ errors.first(`addEditValidation.number_of_days`) }}
            </div>
          </v-col>

            <v-col class="py-0" :cols="6">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.days`),
              }"
            >
             <label>{{$t('Days')}}</label>
              <v-autocomplete
                class="d-block my-2"
                name="number_of_days"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('Days')"
                hide-details
                dense
                outlined
                v-model="formData.days"
                item-value="id"
                item-text="name"
                :items="dayList"
                clearable
                multiple
              ></v-autocomplete>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.days`)"
            >
              {{ errors.first(`addEditValidation.days`) }}
            </div>
          </v-col>

        </v-row>
      </v-container>
    </v-card-text>

       </v-tab-item>
        <v-tab-item>
         <v-container fluid>
           <div>
            <el-button @click="addNew" type="primary float-right mb-5">{{$t('Add')}}</el-button>
           </div>

           <table id="pacakgeFeaturesTable">
            <tr>
              <th>#</th>
              <th>اسم المجموعة</th>
              <th>الكمية</th>
              <th>السعر</th>
              <th>العمليات</th>
            </tr>

            <tr v-for="(l, lIndex) in formData.details" :key="lIndex">
              <td>
              {{  lIndex + 1}}
              </td>
              <td>
                <div
              :class="{
                'has-error': errors.has(`addEditValidation.group${lIndex}`),
              }"
            >
            <label>{{$t('Group')}}</label>
              <v-autocomplete
                class="d-block my-2"
                :name="`group${lIndex}`"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('Group')"
                hide-details
                dense
                outlined
                v-model="l.id"
                item-value="id"
                item-text="name"
                :items="listGroup"
                clearable
              ></v-autocomplete>
            </div>

            <div class="help-block" v-if="errors.has(`addEditValidation.group${lIndex}`)">
              {{ errors.first(`addEditValidation.group${lIndex}`) }}
            </div>
              </td>
              <td>
                 <div :class="{'has-error': errors.has(`addEditValidation.quantity${lIndex}`)}">
                   <label>{{$t('Quantity')}}</label>
                    <v-text-field
                      class="d-block my-2"
                      :name="`quantity${lIndex}`"
                      data-vv-scope="addEditValidation"
                      v-validate="'required'"
                      :data-vv-as="$t('Quantity')"
                      hide-details
                      dense
                      outlined
                      v-model="l.quantity"
                    ></v-text-field>
                  </div>

                <div class="help-block" v-if="errors.has(`addEditValidation.quantity${lIndex}`)">
                  {{ errors.first(`addEditValidation.quantity${lIndex}`) }}
                </div>
              </td>
              <td>
                 <div :class="{'has-error': errors.has(`addEditValidation.Price${lIndex}`)}">
                   <label>{{$t('Price')}}</label>
                    <v-text-field
                      class="d-block my-2"
                      :name="`quantity${lIndex}`"
                      data-vv-scope="addEditValidation"
                      v-validate="'required'"
                      :data-vv-as="$t('Price')"
                      hide-details
                      dense
                      outlined
                      v-model="l.group_price"
                    ></v-text-field>
                  </div>

                <div class="help-block" v-if="errors.has(`addEditValidation.Price${lIndex}`)">
                  {{ errors.first(`addEditValidation.Price${lIndex}`) }}
                </div>
              </td>
              <td>

                  <v-icon
          class="my-0 mr-2 ml-2"
          dense
          @click="
            removeRow(lIndex);
          "
          color="danger"
          >mdi-delete</v-icon
        >

              </td>
            </tr>

           </table>
         </v-container>
       </v-tab-item>
    </v-tabs-items>

     <v-container fluid>
        <v-row class="float-left">
          <v-btn
            class="mr-2 ml-5"
            color="success"
            dark
            outlined
            small
            @click="save"
          >
            {{ $t("Save") }}
          </v-btn>
        </v-row>
      </v-container>
 </div>
</template>

<script>
export default {
  name: "packageAddEdit",
  data() {
    return {
      activeName: 'primaryData',
      formData: {
        name: {
          ar: null,
          en: null
        },
        app_name: {
          ar: null,
          en: null
        },
        price_before_discount: null,
        price: null,
        cost: null,
        number_of_meals: null,
        number_of_days: null,
        details: [], //{id:3,quantity:10}
        days: [], // [0,1]
        file:null,
      },
      imageUrl: null, // Data property to hold the image URL for preview
      fileErrors: [],
      listGroup: [],
      dayList: [
        { id: 6, name: this.$t("Saturday") },
        { id: 0, name: this.$t("Sunday") },
        { id: 1, name: this.$t("Monday") },
        { id: 2, name: this.$t("Tuesday") },
        { id: 3, name: this.$t("Wedensday") },
        { id: 4, name: this.$t("Thursday") },
      ],
    }
  },
   methods: {
     appendFormData(formData, data, parentKey) {
       if (data && typeof data === 'object' && !(data instanceof File)) {
         Object.keys(data).forEach(key => {
           if (data[key] instanceof File) {
             formData.append(key, data[key]);
           } else if (typeof data[key] === 'object' && !(data[key] instanceof File)) {
             this.appendFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
           } else {
             const value = data[key] == null ? '' : data[key]; // Convert null to an empty string
             const formKey = parentKey ? `${parentKey}[${key}]` : key;
             formData.append(formKey, value);
           }
         });
       } else {
         formData.append(parentKey, data);
       }
     },

     // Convert the formData object into a FormData instance
     prepareFormData() {
       const formDataInstance = new FormData();
       this.appendFormData(formDataInstance, this.formData, '');

       return formDataInstance;
     },
    removeRow(index) {
      this.formData.details.splice(index,1)
    },
    addNew() {
      let listObj= {
        id: null,
        quantity: null
      };
      this.formData.details.push(listObj);
    },
      save() {
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");
        const formDataToSubmit = this.prepareFormData();

        if(this.$route.params.id) {
          //edit
          formDataToSubmit.append('id', this.$route.params.id);

          this.$store.dispatch(`package/updateData`, formDataToSubmit).then((res)=> {
            this.notifySuccess("Update", res)
            this.$router.push({name:'package'})
          });
        }
        else {
          this.$store.dispatch(`package/saveData`, formDataToSubmit).then((res)=> {
            this.notifySuccess("Save", res)
            this.$router.push({name:'package'})
          });
        }
      });
      },
     handleFileUpload(file) {
       // Clear previous errors and reset validation state
       this.fileErrors = [];
       this.$validator.reset('addEditValidation.file');

       if (!this.isImageFile(file)) {
         // File is not an image or not one of the allowed types, set a custom error message
         this.fileErrors = [this.$t('Please select a valid image file (PNG, JPG, JPEG).')];
         this.$validator.flag('addEditValidation.file', {
           valid: false,
           validated: true,
         });
       } else {
         // File is valid
         this.formData.file = file;
         this.$validator.flag('addEditValidation.file', {
           valid: true,
           validated: true,
         });
       }
     },
     isImageFile(file) {
       const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
       return allowedTypes.includes(file.type);
     },
    },
    created() {
      if(this.$route.params.id) {
        this.$store.dispatch(`package/findData`, this.$route.params.id).then((res)=> {
          this.imageUrl = res.data.file;
          this.formData= res.data;
          });
      }
      this.$store.dispatch(`package/listGroup`).then(res => {
        this.listGroup= res.data;
      })
    },
};
</script>
<style>
.image-preview img {
  max-width: 50%; /* Ensure the image fits in the container */
  height: auto; /* Maintain the aspect ratio */
  margin-top: 1rem; /* Provide some space above the image */
}

#pacakgeFeaturesTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#pacakgeFeaturesTable td, #pacakgeFeaturesTable th {
  border: 1px solid #ccc !important;
  padding: 8px;
}

#pacakgeFeaturesTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: right;
  background-color: #04AA6D;
  color: white;
}
</style>